.App {
    text-align: center;
    max-width: 800px;
    margin: auto;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}


body {
    background: black;
}

.center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
}

.loading {
    background: black;
    position: relative;
    transform: rotate(0deg);
    height: 200px;
    width: 300px;
}

.stelar {
    transform: translate(0, -50%) rotate(60deg);

    width: 3px;
    height: 200px;
    content: " ";
    display: block;

    background: linear-gradient(to bottom,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 3%,
    rgba(255, 255, 255, 0) 4%,
    rgba(255, 255, 255, 0) 5%,
    rgba(255, 255, 255, 1) 6%,
    rgba(255, 255, 255, 1) 86%,
    rgba(255, 255, 255, 0) 87%,
    rgba(255, 255, 255, 0) 89%,
    rgba(255, 255, 255, 1) 90%,
    rgba(255, 255, 255, 1) 93%,
    rgba(255, 255, 255, 0) 94%,
    rgba(255, 255, 255, 0) 96%,
    rgba(255, 255, 255, 1) 97%,
    rgba(255, 255, 255, 1) 100%);
}

.moon:before,
.moon:after,
.stelar:before,
.stelar:after {
    position: absolute;
    background: white;
    content: " ";
}

.stelar:before,
.stelar:after {
    width: 30px;
    height: 60px;
    left: -29px;
    top: 50%;
    transform: translate(0, -50%);
    border-radius: 30px 0 0 30px;
}

.stelar:before {
    left: 1px;
    height: 30px;
    width: 20px;
    border-radius: 0 20px 20px 0
}

@keyframes gravity {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes gravity2 {
    9% {
        opacity: 0;
    }
    59% {
        opacity: 0;
    }
    68% {
        opacity: 1;
    }
}

.moon {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    transform-origin: 50% 50%;
    animation: gravity 3s linear infinite;
    width: 100%;
    height: 100%;
}

.moon:before {
    top: 50%;
    left: 50%;
    position: absolute;

    transform: translate(200%, -200%);

    width: 20px;
    height: 20px;
    border-radius: 20px;
    background: white;
}

.moon:after {
    top: 50%;
    left: 50%;
    position: absolute;

    transform: translate(-180%, 200%);
    width: 15px;
    height: 15px;
    border-radius: 15px;
    animation: gravity2 3s linear infinite;
}